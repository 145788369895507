<template>
    <div class="page flex-col">
        <div class="box_1 flex-row">
            <div class="section_1 flex-col">

                <header>
                    <van-nav-bar
                            title="新增地址"
                            left-text=""
                            left-arrow
                            @click-left="onClickLeft"
                    />
                </header>

                <div class="group_3 flex-col"></div>
                <div class="text-wrapper_2 flex-row justify-between">
                    <div class="text_6">联系人
                        <input type="text" class="text_7" style="text-indent: 2em; border: none;"
                               v-model="constants.name"
                               placeholder="请输入联系人"/>
                    </div>
                </div>

                <div class="group_4 flex-col"></div>
                <div class="text-wrapper_3 flex-row justify-between">
                    <div class="text_8">联系电话
                        <input type="text" class="text_9" style="text-indent: 1em; border: none;"
                               v-model="constants.phone" placeholder="请输入联系电话"/>
                    </div>
                </div>

                <div class="group_5 flex-col"></div>
                <div class="text-wrapper_4 flex-row justify-between">
                    <div class="text_10">详细地址
                        <input type="text" class="text_11" style="text-indent: 1em; border: none;"
                               v-model="constants.address"
                               placeholder="请输入详细地址"/>
                    </div>
                </div>
                <div class="group_6 flex-col"></div>
                <div class="text-wrapper_5 flex-row justify-between">
                    <div class="text_12">是否默认地址
                        <input type="radio" class="text_13_1" name="isDefault" value="0" style="text-indent: 2em; border: none;"
                               v-model="constants.isDefault" />是
                        <input type="radio" class="text_13_1" name="isDefault" value="1" style="text-indent: 2em; border: none;"
                               v-model="constants.isDefault" />否
                    </div>
                </div>

            </div>
            <div class="section_2 flex-col">
                <div class="text-wrapper_6 flex-col" @click="saveAddress"><span class="text_15">保存</span></div>
            </div>
        </div>
    </div>
</template>
<script>
    import {addAddress,saveAddress,updateAddress} from "@/api/user";

    export default {
        data() {
            return {
                constants: {
                    account: '',
                    addressId:'',
                    phone:'',
                    name:'',
                    address:'',
                    isDefault:1
                }
            };
        },
        mounted() {
            const user = localStorage.getItem('userInfoDs')
            if(user){
                this.constants.account = JSON.parse(user).account
            }else{
                this.$router.push('/login')
            }
            const addressId = this.$route.params.addressId;
            if(addressId){
                updateAddress(addressId).then(response => {
                    if (response.code == 200) {
                        this.constants = response.data
                        /*this.constants.forEach((item) => {
                            if(item.isDefault == 0){

                            }
                        })*/
                    }
                })
            }
        },
        methods: {
            saveAddress() {
                const addressId = this.$route.params.addressId;
                if(addressId){
                    this.constants.addressId = addressId
                    saveAddress(this.constants).then(response => {
                        this.$model({
                            show: true,
                            title: "提示",
                            content: response.msg,
                            confirmButton: false,
                            cancelButton: true
                        });
                    })
                }else{
                    addAddress(this.constants).then(response => {
                        this.$model({
                            show: true,
                            title: "提示",
                            content: response.msg,
                            confirmButton: false,
                            cancelButton: true
                        });
                    })
                }
                this.$router.push('/userAddress')

            },
            onClickLeft() {
                this.$router.push('/userAddress')
            }
        }
    };
</script>
<style scoped lang="css" src="./assets/index.rem.css"/>